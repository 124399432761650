var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vs-sidebar",
    {
      staticClass: "card-data-view items-no-padding",
      class: { "card-data-view-max": _vm.cardViewType == "PROPOSTA" },
      attrs: {
        "click-not-close": "",
        "position-right": "",
        parent: "body",
        "default-index": "1",
        color: "dark",
        spacer: ""
      },
      model: {
        value: _vm.showCardDataView,
        callback: function($$v) {
          _vm.showCardDataView = $$v
        },
        expression: "showCardDataView"
      }
    },
    [
      _c(
        "div",
        { staticClass: "mt-6 flex items-center justify-between px-6" },
        [
          _c("h4", [_vm._v(_vm._s(_vm.cardViewTitle))]),
          _c("feather-icon", {
            staticClass: "cursor-pointer",
            attrs: { icon: "XIcon" },
            on: { click: _vm.onCloseClick }
          })
        ],
        1
      ),
      _c("vs-divider", { staticClass: "mb-0" }),
      _c(
        _vm.scrollbarTag,
        {
          tag: "component",
          staticClass: "scroll-area--card-data-view pt-4 pb-6",
          attrs: { settings: _vm.scrollSettings }
        },
        [
          _vm.cardViewType == "CORRETORA_PEP"
            ? _c("broker-data-view-content", {
                staticClass: "p-8",
                attrs: {
                  data: _vm.cardContentData,
                  currentPipe: _vm.currentPipe,
                  cardId: _vm.cardViewId,
                  stepName: _vm.stepName,
                  cardMovementUser: _vm.cardMovementUser,
                  ownerCard: _vm.ownerCard,
                  ownerList: _vm.ownerList,
                  cardPriorityList: _vm.cardPriorityList,
                  priorityDataClickedCard: _vm.priorityDataClickedCard
                },
                on: { refreshPipe: _vm.refreshPipe }
              })
            : _vm._e(),
          _vm.cardViewType == "NOMEACAO_TOMADOR"
            ? _c("nomeation-request-data-view-content", {
                attrs: {
                  data: _vm.cardContentData,
                  cardId: _vm.cardViewId,
                  stepName: _vm.stepName,
                  cardMovementUser: _vm.cardMovementUser,
                  currentPipe: _vm.currentPipe,
                  ownerCard: _vm.ownerCard,
                  ownerList: _vm.ownerList,
                  cardPriorityList: _vm.cardPriorityList,
                  priorityDataClickedCard: _vm.priorityDataClickedCard
                },
                on: { refreshPipe: _vm.refreshPipe }
              })
            : _vm._e(),
          _vm.cardViewType == "ANALISE_CCG"
            ? _c("cCGDataViewContent", {
                attrs: {
                  data: _vm.cardContentData,
                  cardId: _vm.cardViewId,
                  stepName: _vm.stepName,
                  cardMovementUser: _vm.cardMovementUser,
                  currentPipe: _vm.currentPipe,
                  ownerCard: _vm.ownerCard,
                  ownerList: _vm.ownerList,
                  cardPriorityList: _vm.cardPriorityList,
                  priorityDataClickedCard: _vm.priorityDataClickedCard
                },
                on: { refreshPipe: _vm.refreshPipe }
              })
            : _vm._e(),
          _vm.cardViewType == "PROPOSTA" ||
          _vm.cardViewType == "GRUPO_ECONOMICO" ||
          _vm.cardViewType == "TOMADOR_QSA_PEP" ||
          _vm.cardViewType == "SEGURADO_QSA_PEP" ||
          _vm.cardViewType == "PROPOSTA_RENOVACAO"
            ? _c("ProposalDataViewContent", {
                attrs: {
                  cardType: _vm.cardViewType,
                  data: _vm.cardContentData,
                  socios: _vm.data,
                  currentPipe: _vm.currentPipe,
                  cardId: _vm.cardViewId,
                  permissoesCocorretagemMapfre:
                    _vm.permissoesCocorretagemMapfre,
                  permissoesResseguroMapfre: _vm.permissoesResseguroMapfre,
                  permissoesCosseguroMapfre: _vm.permissoesCosseguroMapfre,
                  stepName: _vm.stepName,
                  cardMovementUser: _vm.cardMovementUser,
                  ownerCard: _vm.ownerCard,
                  ownerList: _vm.ownerList,
                  cardPriorityList: _vm.cardPriorityList,
                  priorityDataClickedCard: _vm.priorityDataClickedCard
                },
                on: {
                  refreshPipe: _vm.refreshPipe,
                  reload: function($event) {
                    return _vm.$emit("reload")
                  }
                }
              })
            : _vm._e(),
          [
            "ENDOSSO_CANCELAMENTO",
            "ENDOSSO_ALTERACAO",
            "ENDOSSO_ANIVERSARIO"
          ].includes(_vm.cardViewType)
            ? _c("EndorsementDataViewContent", {
                attrs: {
                  cardType: _vm.cardViewType,
                  data: _vm.cardContentData,
                  cardId: _vm.cardViewId,
                  stepName: _vm.stepName,
                  cardMovementUser: _vm.cardMovementUser,
                  currentPipe: _vm.currentPipe,
                  ownerCard: _vm.ownerCard,
                  ownerList: _vm.ownerList,
                  cardPriorityList: _vm.cardPriorityList,
                  priorityDataClickedCard: _vm.priorityDataClickedCard
                },
                on: { refreshPipe: _vm.refreshPipe }
              })
            : _vm._e(),
          _vm.cardViewType == "ANALISE_DOCUMENTACAO_CORRETORA" ||
          _vm.cardViewType == "ANALISE_CONFORMIDADE_CORRETORA"
            ? _c("DocumentsDataViewContent", {
                attrs: {
                  data: _vm.cardContentData,
                  cardId: _vm.cardViewId,
                  metasValidationClickedCard: _vm.metasValidationClickedCard,
                  stepName: _vm.stepName,
                  cardMovementUser: _vm.cardMovementUser,
                  currentPipe: _vm.currentPipe,
                  ownerCard: _vm.ownerCard,
                  ownerList: _vm.ownerList,
                  cardPriorityList: _vm.cardPriorityList,
                  priorityDataClickedCard: _vm.priorityDataClickedCard
                },
                on: { refreshPipe: _vm.refreshPipe }
              })
            : _vm._e(),
          _vm.cardViewType == "RAMO_ATIVIDADE"
            ? _c("BranchActivityDataViewContent", {
                attrs: {
                  cardType: _vm.cardViewType,
                  data: _vm.cardContentData,
                  cardId: _vm.cardViewId,
                  stepName: _vm.stepName,
                  cardMovementUser: _vm.cardMovementUser,
                  currentPipe: _vm.currentPipe,
                  ownerCard: _vm.ownerCard,
                  ownerList: _vm.ownerList,
                  cardPriorityList: _vm.cardPriorityList,
                  priorityDataClickedCard: _vm.priorityDataClickedCard
                },
                on: { refreshPipe: _vm.refreshPipe }
              })
            : _vm._e(),
          _vm.cardViewType == "REQUEST_LIMIT"
            ? _c("RequestLimitDataViewContent", {
                attrs: {
                  data: _vm.cardContentData,
                  cardId: _vm.cardViewId,
                  stepName: _vm.stepName,
                  cardMovementUser: _vm.cardMovementUser,
                  currentPipe: _vm.currentPipe,
                  ownerCard: _vm.ownerCard,
                  ownerList: _vm.ownerList,
                  cardPriorityList: _vm.cardPriorityList,
                  priorityDataClickedCard: _vm.priorityDataClickedCard
                },
                on: { refreshPipe: _vm.refreshPipe }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }