var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dados-gerais p-4 pt-2" },
    [
      _c(
        "vs-tabs",
        { attrs: { color: "warning" } },
        [
          _c(
            "vs-tab",
            { attrs: { label: "Dados Solicitacao" } },
            [
              _c("vs-card", { staticClass: "mt-4 p-8 dados-proposta" }, [
                !_vm.Newe
                  ? _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col w-1/2" },
                        [
                          _c(
                            "p",
                            { staticClass: "text-sm font-semibold mb-1" },
                            [_vm._v("Responsável")]
                          ),
                          _c(
                            "vs-select",
                            {
                              on: { change: _vm.atualizarResponsavel },
                              model: {
                                value: _vm.selectedOwnerCard,
                                callback: function($$v) {
                                  _vm.selectedOwnerCard = $$v
                                },
                                expression: "selectedOwnerCard"
                              }
                            },
                            _vm._l(_vm.ownerList, function(item, index) {
                              return _c("vs-select-item", {
                                key: index,
                                attrs: { value: item, text: item }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col w-1/2" },
                        [
                          _c(
                            "p",
                            { staticClass: "text-sm font-semibold mb-1" },
                            [_vm._v("Prioridade")]
                          ),
                          _c(
                            "vs-select",
                            {
                              on: { change: _vm.atualizarPrioridade },
                              model: {
                                value: _vm.selectedPriorityId,
                                callback: function($$v) {
                                  _vm.selectedPriorityId = $$v
                                },
                                expression: "selectedPriorityId"
                              }
                            },
                            _vm._l(_vm.cardPriorityList, function(item, index) {
                              return _c("vs-select-item", {
                                key: index,
                                attrs: { value: item.Id, text: item.Name }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm.deveExibirResponsavelMovimentacao
                  ? _c("div", { staticClass: "vx-row mt-4" }, [
                      _c("div", { staticClass: "vx-col w-1/2" }, [
                        _c("p", { staticClass: "text-sm font-semibold" }, [
                          _vm._v(_vm._s(_vm.stepName) + " por")
                        ]),
                        _c("p", { staticClass: "text-lg" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                (_vm.cardMovementUser || {}).Name
                                  ? (_vm.cardMovementUser || {}).Name
                                  : "-"
                              ) +
                              "\n            "
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "vx-col w-1/2" }, [
                        _c("p", { staticClass: "text-sm font-semibold" }, [
                          _vm._v("Data da movimentação")
                        ]),
                        _c("p", { staticClass: "text-lg" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm._f("moment")(
                                  (_vm.cardMovementUser || {}).MovingDate
                                    ? (_vm.cardMovementUser || {}).MovingDate
                                    : "-",
                                  "DD/MM/YYYY HH:mm"
                                )
                              ) +
                              "\n            "
                          )
                        ])
                      ])
                    ])
                  : _vm._e()
              ]),
              _c("vs-card", { staticClass: "mt-4 p-8 dados-proposta" }, [
                _c("div", { staticClass: "vx-col w-1/2" }, [
                  _c("p", { staticClass: "text-sm font-semibold" }, [
                    _vm._v("Data da solicitação")
                  ]),
                  _c("p", { staticClass: "text-lg" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("moment")(
                            _vm.data.startDate,
                            "DD/MM/YYYY HH:mm"
                          )
                        )
                    )
                  ])
                ])
              ]),
              _c("vs-card", { staticClass: "mt-4 p-8 dados-proposta" }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-1/2" },
                    [
                      _c("p", { staticClass: "text-sm font-semibold" }, [
                        _vm._v("CNPJ")
                      ]),
                      _vm.data.broker.Cnpj
                        ? _c("the-mask", {
                            staticClass: "text-lg invisible-input",
                            attrs: {
                              mask: ["##.###.###/####-##"],
                              disabled: "true",
                              masked: true,
                              value: _vm.data.broker.Cnpj
                            }
                          })
                        : _c("p", { staticClass: "text-lg" }, [
                            _vm._v(" Não informado")
                          ])
                    ],
                    1
                  ),
                  _c("div", { staticClass: "vx-col w-1/2" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Usuário solicitante")
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(_vm._s(_vm.data.broker.usuario || "Não informado"))
                    ])
                  ])
                ])
              ]),
              _c("vs-card", { staticClass: "mt-4 p-8 dados-proposta" }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-1/2" },
                    [
                      _c("p", { staticClass: "text-sm font-semibold" }, [
                        _vm._v("CNPJ Tomador")
                      ]),
                      _c("the-mask", {
                        staticClass: "text-lg invisible-input",
                        attrs: {
                          mask: ["##.###.###/####-##"],
                          disabled: "true",
                          masked: true,
                          value: _vm.data.policyHolder.Cnpj
                        }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "vx-col w-1/2" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Tomador")
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        _vm._s(_vm.data.policyHolder.Name || "Não informado")
                      )
                    ])
                  ])
                ])
              ]),
              _vm.data.broker
                ? _c("vs-card", { staticClass: "mt-4 p-8 dados-proposta" }, [
                    _c("div", { staticClass: "vx-row" }, [
                      _c("div", { staticClass: "vx-col w-full mb-2" }, [
                        _c("p", { staticClass: "text-sm font-semibold" }, [
                          _vm._v("Corretor")
                        ]),
                        _c("p", { staticClass: "text-lg" }, [
                          _vm._v(
                            _vm._s(_vm.data.broker.Corretora || "Não informado")
                          )
                        ])
                      ]),
                      _vm.data.broker.Cnpj
                        ? _c(
                            "div",
                            { staticClass: "vx-col w-1/3" },
                            [
                              _c(
                                "p",
                                { staticClass: "text-sm font-semibold" },
                                [_vm._v("CNPJ Corretor")]
                              ),
                              _c("the-mask", {
                                staticClass: "text-lg invisible-input",
                                attrs: {
                                  mask: ["##.###.###/####-##"],
                                  disabled: "true",
                                  masked: true,
                                  value: _vm.data.broker.Cnpj
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("div", { staticClass: "vx-col w-1/3" }, [
                        _c("p", { staticClass: "text-sm font-semibold" }, [
                          _vm._v("Usuário")
                        ]),
                        _c("p", { staticClass: "text-lg" }, [
                          _vm._v(
                            _vm._s(_vm.data.broker.usuario || "Não informado")
                          )
                        ])
                      ])
                    ])
                  ])
                : _vm._e(),
              _c("vs-card", { staticClass: "mt-4 p-8 dados-proposta" }, [
                _c("div", { staticClass: "vx-row md:mt-5" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col md:w-12/12 w-full md:mt-0 mt-5" },
                    [
                      _c("p", { staticClass: "text-sm font-semibold" }, [
                        _vm._v("Comentário")
                      ]),
                      _c("vs-textarea", {
                        attrs: { disabled: !_vm.hasPermission },
                        model: {
                          value: _vm.data.comment,
                          callback: function($$v) {
                            _vm.$set(_vm.data, "comment", $$v)
                          },
                          expression: "data.comment"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _c("div", { staticClass: "vx-row mt-5" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-6/12 w-full md:mt-0 mt-5" },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "detalhe-tomador",
                            params: {
                              tomadorUniqueId: _vm.tomador.policyHolder.UniqueId
                            }
                          },
                          target: "_blank"
                        }
                      },
                      [
                        _c(
                          "vs-button",
                          { attrs: { color: "primary", type: "flat" } },
                          [
                            _c("span", { staticClass: "font-semibold" }, [
                              _vm._v(
                                "\n                Visualizar cadastro completo\n              "
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c("vs-tab", { attrs: { label: "Parecer" } }, [
            _c(
              "div",
              { staticClass: "vx-row md:mt-6" },
              [
                _vm._l(_vm.observations, function(observation, index) {
                  return _c(
                    "vs-collapse",
                    {
                      key: index,
                      staticClass: "vx-col md:w-12/12 w-full md:mt-0 mt-5",
                      attrs: { type: "margin" }
                    },
                    [
                      _c("vs-collapse-item", [
                        _c(
                          "div",
                          { attrs: { slot: "header" }, slot: "header" },
                          [
                            _c("div", { staticClass: "vx-row" }, [
                              _c("div", { staticClass: "vx-col" }, [
                                _c("span", { staticClass: "font-semibold" }, [
                                  _vm._v(
                                    "Responsável: " +
                                      _vm._s(observation.UserName)
                                  )
                                ])
                              ]),
                              _c("div", { staticClass: "vx-col" }, [
                                _c("span", { staticClass: "font-semibold" }, [
                                  _vm._v(
                                    "Data de cadastro:\n                    " +
                                      _vm._s(
                                        _vm._f("moment")(
                                          observation.CreateDate,
                                          "DD/MM/YYYY HH:mm"
                                        )
                                      )
                                  )
                                ])
                              ])
                            ])
                          ]
                        ),
                        _c("p", {
                          staticClass: "vx-row flex px-8",
                          domProps: {
                            innerHTML: _vm._s(observation.Observation)
                          }
                        })
                      ])
                    ],
                    1
                  )
                }),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-12/12 w-full md:mt-0 mt-5" },
                  [
                    _c("jodit-editor", {
                      attrs: { "read-only": "true" },
                      model: {
                        value: _vm.parecer,
                        callback: function($$v) {
                          _vm.parecer = $$v
                        },
                        expression: "parecer"
                      }
                    }),
                    _vm.hasPermission
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "pt-4 mt-5 flex flex-row-reverse w-full"
                          },
                          [
                            _c(
                              "vs-button",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.saveParecer(true)
                                  }
                                }
                              },
                              [_vm._v("Salvar parecer")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              2
            )
          ]),
          _c("vs-tab", { attrs: { label: "Documentos" } }, [
            _c("div", [
              _c(
                "div",
                _vm._l(_vm.listaDocumentos, function(documento, index) {
                  return _c(
                    "vs-card",
                    { key: index, staticClass: "mt-1 p-2" },
                    [
                      _c("div", { staticClass: "vx-row flex" }, [
                        _c("div", { staticClass: "vx-col flex-1" }, [
                          _c("p", { staticClass: "text-sm font-semibold" }),
                          _c("p", { staticClass: "text-lg" }, [
                            _vm._v(_vm._s(documento.Name || "Não informado"))
                          ]),
                          _c("p", { staticClass: "text-sm pt-4" }, [
                            _c("b", [_vm._v("Status ")]),
                            _vm._v(
                              _vm._s(documento.StatusDescription) +
                                "\n                "
                            )
                          ]),
                          _c("p", { staticClass: "text-sm pt-4" }, [
                            _c("b", [_vm._v("Total de anexos ")]),
                            _vm._v(
                              _vm._s(documento.TotalArchives) +
                                "\n                "
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "vx-col" }, [
                          _c(
                            "div",
                            {
                              staticClass: "text-right w-full flex items-center"
                            },
                            [
                              documento.TotalArchives > 0
                                ? _c("vs-button", {
                                    staticClass: "delete",
                                    attrs: {
                                      color: "primary",
                                      type: "flat",
                                      size: "large",
                                      icon: "get_app"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.downloadDocumento(
                                          documento.UniqueId
                                        )
                                      }
                                    }
                                  })
                                : _vm._e(),
                              documento.StatusId == 3 && _vm.hasPermission
                                ? _c("vs-button", {
                                    staticClass: "delete",
                                    attrs: {
                                      color: "success",
                                      type: "flat",
                                      size: "large",
                                      icon: "thumb_up"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.alterarStatusDocumento(
                                          documento,
                                          1
                                        )
                                      }
                                    }
                                  })
                                : _vm._e(),
                              documento.StatusId == 3 && _vm.hasPermission
                                ? _c("vs-button", {
                                    staticClass: "delete",
                                    attrs: {
                                      color: "warning",
                                      type: "flat",
                                      size: "large",
                                      icon: "thumb_down"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.alterarStatusDocumento(
                                          documento,
                                          4
                                        )
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ])
                      ])
                    ]
                  )
                }),
                1
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup-lista-documentos",
          attrs: {
            title: "Solicitar documentação",
            active: _vm.popupListaDocumentos
          },
          on: {
            "update:active": function($event) {
              _vm.popupListaDocumentos = $event
            }
          }
        },
        [
          _c(
            "vs-tabs",
            { attrs: { color: "warning" } },
            [
              _c("vs-tab", { attrs: { label: "Outros documentos" } }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      (_vm.documentosManuaisParaSolicitar || []).length == 0
                        ? _c("div", { staticClass: "vx-row" }, [
                            _c(
                              "div",
                              { staticClass: "vx-col w-full mt-2" },
                              [
                                _c(
                                  "vs-alert",
                                  {
                                    staticClass: "h-auto",
                                    attrs: { color: "warning" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Nenhum documento adicionado. Clique no botão de adicionar\n                  documentos;\n                "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      _vm._l(_vm.documentosManuaisParaSolicitar || [], function(
                        documentoManual,
                        index
                      ) {
                        return _c(
                          "div",
                          { key: index, staticClass: "vx-row" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vx-col md:w-10/12 w-full mt-5 md:pr-2"
                              },
                              [
                                _c("vs-input", {
                                  staticClass: "w-full",
                                  attrs: {
                                    "label-placeholder": "Documento",
                                    size: "large"
                                  },
                                  model: {
                                    value: documentoManual.Name,
                                    callback: function($$v) {
                                      _vm.$set(documentoManual, "Name", $$v)
                                    },
                                    expression: "documentoManual.Name"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vx-col md:w-2/12 w-full mt-5 md:pr-0 md:pl-2 p-0 flex items-center"
                              },
                              [
                                _c("vs-button", {
                                  staticClass: "delete mt-4 ml-2",
                                  attrs: {
                                    color: "primary",
                                    type: "flat",
                                    size: "large",
                                    icon: "delete"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.removeDocumentoManual(index)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      }),
                      _c("div", { staticClass: "vx-row" }, [
                        _c(
                          "div",
                          { staticClass: "vx-col w-full" },
                          [
                            _c(
                              "vs-button",
                              {
                                staticClass: "font-semibold mt-2 float-right",
                                attrs: {
                                  "icon-after": "",
                                  color: "primary",
                                  type: "flat",
                                  icon: "add_circle"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.adicionaDocumentoManual()
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                  Adicionar novo Documento\n                "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "vx-row" }, [
                        _c(
                          "div",
                          { staticClass: "vx-col pt-5 text-right w-full mt-2" },
                          [
                            _c(
                              "vs-button",
                              {
                                attrs: { color: "primary" },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.solicitarDocumentosManuais()
                                  }
                                }
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "font-semibold pr-2 pl-2" },
                                  [
                                    _vm._v(
                                      "\n                    Solicitar documentação\n                  "
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    2
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup-download-arquivos",
          attrs: {
            title: "Anexos do documento",
            active: _vm.popupListaArquivos
          },
          on: {
            "update:active": function($event) {
              _vm.popupListaArquivos = $event
            }
          }
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c("h5", [_vm._v(_vm._s((_vm.documentoSelecionado || {}).Name))])
            ])
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col pt-5 w-full mt-2" },
              _vm._l(_vm.listaDocumentosDetalhes, function(archive, index) {
                return _c("vs-card", { key: index, staticClass: "mt-1 p-2" }, [
                  _c("div", { staticClass: "vx-row flex" }, [
                    _c("div", { staticClass: "vx-col flex-1" }, [
                      _c("p", { staticClass: "text-sm font-semibold" }),
                      _c("p", { staticClass: "text-lg" }, [
                        _vm._v(_vm._s(archive.Name || "Não informado"))
                      ]),
                      _c("p", { staticClass: "text-sm" }, [
                        _c("b", [_vm._v("Anexado em ")]),
                        _vm._v(
                          _vm._s(
                            _vm._f("moment")(archive.CreateDate, "DD/MM/YYYY")
                          ) + "\n              "
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "vx-col" }, [
                      _c(
                        "div",
                        { staticClass: "text-right w-full flex items-center" },
                        [
                          _c("vs-button", {
                            staticClass: "delete",
                            attrs: {
                              color: "primary",
                              type: "flat",
                              size: "large",
                              icon: "get_app"
                            },
                            on: {
                              click: function($event) {
                                return _vm.baixarArquivo(archive)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ])
              }),
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }