var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { on: { click: _vm.onCardClick } },
    [
      _c(
        "vs-card",
        { class: "cardx " + _vm.object.cardType, attrs: { actionable: "" } },
        [
          _c(
            "div",
            { attrs: { slot: "header" }, slot: "header" },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "light",
                    content: _vm.data.header,
                    placement: "right"
                  }
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "text-lg font-medium",
                      class: { "compact-text": /^\d*$/.test(_vm.data.header) }
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.data.header) + "\n        "
                      )
                    ]
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            [
              (_vm.data.body || {}).p1
                ? _c("p", { staticClass: "text-base" }, [
                    _vm._v(_vm._s(_vm.data.body.p1))
                  ])
                : _vm._e(),
              (_vm.data.body || {}).p2
                ? _c("p", { staticClass: "text-sm" }, [
                    _vm._v(_vm._s(_vm.data.body.p2))
                  ])
                : _vm._e(),
              (_vm.data.body || {}).p3
                ? _c("p", { staticClass: "text-xs" }, [
                    _vm._v(_vm._s(_vm.data.body.p3))
                  ])
                : _vm._e(),
              (_vm.data.body || {}).metas
                ? _vm._l(_vm.data.body.metas, function(meta) {
                    return _c(
                      "p",
                      { key: meta.name, staticClass: "text-sm break-word" },
                      [
                        _c("b", [_vm._v(_vm._s(meta.description) + ":")]),
                        _vm._v(" " + _vm._s(meta.value) + "\n        ")
                      ]
                    )
                  })
                : _vm._e()
            ],
            2
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              (_vm.data.footer || {}).hideFooterDetailedButton || false == false
                ? _c("p", [_vm._v("\n         \n      ")])
                : _vm._e(),
              !(_vm.data.footer || {}).hideFooterDetailedButton && false == true
                ? _c(
                    "vs-row",
                    { attrs: { "vs-justify": "flex-end" } },
                    [
                      _c("vs-button", {
                        attrs: { icon: "visibility" },
                        on: { click: _vm.onCardVisibilityButtonClick }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }