var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dados-gerais p-4 pt-2" },
    [
      _c(
        "vs-tabs",
        { attrs: { color: "warning" } },
        [
          _c(
            "vs-tab",
            { attrs: { label: "Cadastro" } },
            [
              _c("vs-card", { staticClass: "mt-4 p-8" }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-1/2" },
                    [
                      _c("p", { staticClass: "text-sm font-semibold mb-1" }, [
                        _vm._v("Responsável")
                      ]),
                      _c(
                        "vs-select",
                        {
                          on: { change: _vm.atualizarResponsavel },
                          model: {
                            value: _vm.selectedOwnerCard,
                            callback: function($$v) {
                              _vm.selectedOwnerCard = $$v
                            },
                            expression: "selectedOwnerCard"
                          }
                        },
                        _vm._l(_vm.ownerList, function(item, index) {
                          return _c("vs-select-item", {
                            key: index,
                            attrs: { value: item, text: item }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col w-1/2" },
                    [
                      _c("p", { staticClass: "text-sm font-semibold mb-1" }, [
                        _vm._v("Prioridade")
                      ]),
                      _c(
                        "vs-select",
                        {
                          on: { change: _vm.atualizarPrioridade },
                          model: {
                            value: _vm.selectedPriorityId,
                            callback: function($$v) {
                              _vm.selectedPriorityId = $$v
                            },
                            expression: "selectedPriorityId"
                          }
                        },
                        _vm._l(_vm.cardPriorityList, function(item, index) {
                          return _c("vs-select-item", {
                            key: index,
                            attrs: { value: item.Id, text: item.Name }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ]),
                _vm.deveExibirResponsavelMovimentacao
                  ? _c("div", { staticClass: "vx-row mt-4" }, [
                      _c("div", { staticClass: "vx-col w-1/2" }, [
                        _c("p", { staticClass: "text-sm font-semibold" }, [
                          _vm._v(_vm._s(_vm.stepName) + " por")
                        ]),
                        _c("p", { staticClass: "text-lg" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                (_vm.cardMovementUser || {}).Name
                                  ? (_vm.cardMovementUser || {}).Name
                                  : "-"
                              ) +
                              "\n            "
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "vx-col w-1/2" }, [
                        _c("p", { staticClass: "text-sm font-semibold" }, [
                          _vm._v("Data da movimentação")
                        ]),
                        _c("p", { staticClass: "text-lg" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm._f("moment")(
                                  (_vm.cardMovementUser || {}).MovingDate
                                    ? (_vm.cardMovementUser || {}).MovingDate
                                    : "-",
                                  "DD/MM/YYYY HH:mm"
                                )
                              ) +
                              "\n            "
                          )
                        ])
                      ])
                    ])
                  : _vm._e()
              ]),
              _c("vs-card", { staticClass: "mt-4 p-8" }, [
                _c("div", { staticClass: "vx-row " }, [
                  _c(
                    "div",
                    { staticClass: "vx-col md:w-6/12 w-full md:mt-0 mt-5" },
                    [
                      _c("p", { staticClass: "text-sm font-semibold" }, [
                        _vm._v("Data de Cadastro")
                      ]),
                      _c("p", { staticClass: "text-lg" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm._f("moment")(
                                _vm.tomador.CreateDate,
                                "DD/MM/YYYY"
                              )
                            ) +
                            "\n            "
                        )
                      ])
                    ]
                  )
                ]),
                _c("div", { staticClass: "vx-row mt-4" }, [
                  _c("div", { staticClass: "vx-col w-6/12" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Tomador")
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(_vm._s(_vm.tomador.Name))
                    ])
                  ]),
                  _c("div", { staticClass: "vx-col w-6/12" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Cnpj")
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(_vm._s(_vm.tomador.CpfCnpj))
                    ])
                  ])
                ]),
                _c("div", { staticClass: "vx-row mb-6 mt-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-1/2" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "detalhe-tomador",
                              params: { tomadorUniqueId: _vm.tomador.UniqueId }
                            },
                            target: "_blank"
                          }
                        },
                        [
                          _c("span", { staticClass: "font-semibold" }, [
                            _vm._v(
                              "\n                Visualizar cadastro completo do tomador\n              "
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ]),
                (_vm.tomador || {}).PersonRisk.BranchActivity
                  ? _c("div", { staticClass: "vx-row mt-4" }, [
                      _c("div", { staticClass: "vx-col" }, [
                        _c("p", { staticClass: "text-sm font-semibold" }, [
                          _vm._v("Ramo de atividade")
                        ]),
                        _c("p", { staticClass: "text-lg" }, [
                          _vm._v(_vm._s(_vm.tomador.PersonRisk.BranchActivity))
                        ])
                      ])
                    ])
                  : _vm._e()
              ]),
              !(_vm.tomador || {}).PersonRisk.BranchActivity
                ? _c("vs-card", { staticClass: "mt-4 p-8" }, [
                    _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col w-full" },
                        [
                          _c(
                            "vs-alert",
                            {
                              staticClass: "h-auto",
                              attrs: { color: "warning" }
                            },
                            [
                              _vm._v(
                                "\n              Ramo de atividade não definido. Escolha o ramo de atividade e\n              clique em salvar.\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "vx-row mt-5" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col w-full" },
                        [
                          _c("v-select", {
                            staticClass: "w-full",
                            attrs: {
                              label: "BranchNameFull",
                              "show-no-options": false,
                              "show-no-results": false,
                              options: _vm.branchs,
                              clearable: false,
                              placeholder: "Selecione uma opção",
                              disabled: !_vm.hasPermission
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "option",
                                  fn: function(option) {
                                    return [
                                      _c("span", { staticClass: "fa" }),
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(option.Code) +
                                          " " +
                                          _vm._s(option.Name) +
                                          "\n              "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1054597575
                            ),
                            model: {
                              value: _vm.branchActivity,
                              callback: function($$v) {
                                _vm.branchActivity = $$v
                              },
                              expression: "branchActivity"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm.hasPermission
                      ? _c("div", { staticClass: "vx-row" }, [
                          _c(
                            "div",
                            { staticClass: "vx-col text-right w-full mt-2" },
                            [
                              _c(
                                "vs-button",
                                {
                                  attrs: { color: "primary" },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.salvarRamoAtividade()
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "font-semibold pr-2 pl-2" },
                                    [
                                      _vm._v(
                                        "\n                Salvar ramo de atividade\n              "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }