var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dados-gerais p-4 pt-2" },
    [
      _c(
        "vs-tabs",
        { attrs: { color: "warning" } },
        [
          _c(
            "vs-tab",
            { attrs: { label: "Solicitação" } },
            [
              _c("vs-card", { staticClass: "mt-4 p-8 dados-proposta" }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-1/2" },
                    [
                      _c("p", { staticClass: "text-sm font-semibold mb-1" }, [
                        _vm._v("Responsável")
                      ]),
                      _c(
                        "vs-select",
                        {
                          on: { change: _vm.atualizarResponsavel },
                          model: {
                            value: _vm.selectedOwnerCard,
                            callback: function($$v) {
                              _vm.selectedOwnerCard = $$v
                            },
                            expression: "selectedOwnerCard"
                          }
                        },
                        _vm._l(_vm.ownerList, function(item, index) {
                          return _c("vs-select-item", {
                            key: index,
                            attrs: { value: item, text: item }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col w-1/2" },
                    [
                      _c("p", { staticClass: "text-sm font-semibold mb-1" }, [
                        _vm._v("Prioridade")
                      ]),
                      _c(
                        "vs-select",
                        {
                          on: { change: _vm.atualizarPrioridade },
                          model: {
                            value: _vm.selectedPriorityId,
                            callback: function($$v) {
                              _vm.selectedPriorityId = $$v
                            },
                            expression: "selectedPriorityId"
                          }
                        },
                        _vm._l(_vm.cardPriorityList, function(item, index) {
                          return _c("vs-select-item", {
                            key: index,
                            attrs: { value: item.Id, text: item.Name }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ]),
                _vm.deveExibirResponsavelMovimentacao
                  ? _c("div", { staticClass: "vx-row mt-4" }, [
                      _c("div", { staticClass: "vx-col w-1/2" }, [
                        _c("p", { staticClass: "text-sm font-semibold" }, [
                          _vm._v(_vm._s(_vm.stepName) + " por")
                        ]),
                        _c("p", { staticClass: "text-lg" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                (_vm.cardMovementUser || {}).Name
                                  ? (_vm.cardMovementUser || {}).Name
                                  : "-"
                              ) +
                              "\n            "
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "vx-col w-1/2" }, [
                        _c("p", { staticClass: "text-sm font-semibold" }, [
                          _vm._v("Data da movimentação")
                        ]),
                        _c("p", { staticClass: "text-lg" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm._f("moment")(
                                  (_vm.cardMovementUser || {}).MovingDate
                                    ? (_vm.cardMovementUser || {}).MovingDate
                                    : "-",
                                  "DD/MM/YYYY HH:mm"
                                )
                              ) +
                              "\n            "
                          )
                        ])
                      ])
                    ])
                  : _vm._e()
              ]),
              _c("vs-card", { staticClass: "mt-4 p-8 dados-proposta" }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c("div", { staticClass: "vx-col w-1/2" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Data da solicitação")
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(_vm._s(_vm.nomeacaoRequestDate || "Não informado"))
                    ])
                  ])
                ])
              ]),
              _c("vs-card", { staticClass: "mt-4 p-8 dados-proposta" }, [
                _c("div", { staticClass: "vx-row mb-6 mt-2" }, [
                  _c("div", { staticClass: "vx-col w-1/2" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Tomador")
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        _vm._s(_vm.nomeacao.PolicyHolderName || "Não informado")
                      )
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "vx-col w-1/2" },
                    [
                      _c("p", { staticClass: "text-sm font-semibold" }, [
                        _vm._v("CNPJ Tomador")
                      ]),
                      _c("the-mask", {
                        staticClass: "text-lg invisible-input",
                        attrs: {
                          mask: ["##.###.###/####-##"],
                          disabled: "true",
                          masked: true,
                          value: _vm.nomeacao.PolicyHolderCpfCnpj
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "vx-row mb-6 mt-2" }, [
                  _c("div", { staticClass: "vx-col w-1/2" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Corretora Solicitante")
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(_vm._s(_vm.nomeacao.BrokerName || "Não informado"))
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "vx-col w-1/2" },
                    [
                      _c("p", { staticClass: "text-sm font-semibold" }, [
                        _vm._v("CNPJ Corretora")
                      ]),
                      _c("the-mask", {
                        staticClass: "text-lg invisible-input",
                        attrs: {
                          mask: ["##.###.###/####-##"],
                          disabled: "true",
                          masked: true,
                          value: _vm.nomeacao.BrokerCpfCnpj
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "vx-col flex justify-end" },
                  [
                    _c(
                      "vs-button",
                      {
                        attrs: { color: "primary" },
                        nativeOn: {
                          click: function($event) {
                            return _vm.baixarCartaDeNomeacao()
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "font-semibold pr-2 pl-2" }, [
                          _vm._v(
                            "\n              Baixar carta de nomeação\n            "
                          )
                        ])
                      ]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }