var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dados-gerais p-4 pt-2" },
    [
      _c(
        "vs-tabs",
        { attrs: { color: "warning" } },
        [
          _c(
            "vs-tab",
            { attrs: { label: "Dados Corretora" } },
            [
              _c("vs-card", { staticClass: "mt-4 p-8 dados-proposta" }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-1/2" },
                    [
                      _c("p", { staticClass: "text-sm font-semibold mb-1" }, [
                        _vm._v("Responsável")
                      ]),
                      _c(
                        "vs-select",
                        {
                          on: { change: _vm.atualizarResponsavel },
                          model: {
                            value: _vm.selectedOwnerCard,
                            callback: function($$v) {
                              _vm.selectedOwnerCard = $$v
                            },
                            expression: "selectedOwnerCard"
                          }
                        },
                        _vm._l(_vm.ownerList, function(item, index) {
                          return _c("vs-select-item", {
                            key: index,
                            attrs: { value: item, text: item }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col w-1/2" },
                    [
                      _c("p", { staticClass: "text-sm font-semibold mb-1" }, [
                        _vm._v("Prioridade")
                      ]),
                      _c(
                        "vs-select",
                        {
                          on: { change: _vm.atualizarPrioridade },
                          model: {
                            value: _vm.selectedPriorityId,
                            callback: function($$v) {
                              _vm.selectedPriorityId = $$v
                            },
                            expression: "selectedPriorityId"
                          }
                        },
                        _vm._l(_vm.cardPriorityList, function(item, index) {
                          return _c("vs-select-item", {
                            key: index,
                            attrs: { value: item.Id, text: item.Name }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ]),
                _vm.deveExibirResponsavelMovimentacao
                  ? _c("div", { staticClass: "vx-row mt-4" }, [
                      _c("div", { staticClass: "vx-col w-1/2" }, [
                        _c("p", { staticClass: "text-sm font-semibold" }, [
                          _vm._v(_vm._s(_vm.stepName) + " por")
                        ]),
                        _c("p", { staticClass: "text-lg" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                (_vm.cardMovementUser || {}).Name
                                  ? (_vm.cardMovementUser || {}).Name
                                  : "-"
                              ) +
                              "\n            "
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "vx-col w-1/2" }, [
                        _c("p", { staticClass: "text-sm font-semibold" }, [
                          _vm._v("Data da movimentação")
                        ]),
                        _c("p", { staticClass: "text-lg" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm._f("moment")(
                                  (_vm.cardMovementUser || {}).MovingDate
                                    ? (_vm.cardMovementUser || {}).MovingDate
                                    : "-",
                                  "DD/MM/YYYY HH:mm"
                                )
                              ) +
                              "\n            "
                          )
                        ])
                      ])
                    ])
                  : _vm._e()
              ]),
              _c("vs-card", { staticClass: "mt-4 p-8 dados-proposta" }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c("div", { staticClass: "vx-col w-1/2" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Data do cadastro")
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(_vm._s(_vm.corretoraCreateDate || "Não informado"))
                    ])
                  ])
                ])
              ]),
              _c("vs-card", { staticClass: "mt-4 p-8 dados-proposta" }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c("div", { staticClass: "vx-col w-1/2" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Razão Social")
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(_vm._s(_vm.corretora.Name || "Não informado"))
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "vx-col md:w-6/12 w-full" },
                    [
                      _c("p", { staticClass: "text-sm font-semibold" }, [
                        _vm._v("CNPJ")
                      ]),
                      _c("the-mask", {
                        staticClass: " text-lg invisible-input ",
                        attrs: {
                          mask: ["##.###.###/####-##"],
                          disabled: "true",
                          masked: true,
                          value: _vm.corretora.CpfCnpj
                        }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "vx-col w-1/2 mt-4" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Situação")
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        _vm._s(_vm.corretora.SUSEPStatus || "Não informado")
                      )
                    ])
                  ])
                ])
              ]),
              _c("vs-card", { staticClass: "mt-4 p-8 dados-proposta" }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c("div", { staticClass: "vx-col w-1/2" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Inscrição Municipal")
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        _vm._s(
                          _vm.corretora.InscricaoMunicipal || "Não informado"
                        )
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "vx-col  w-1/1 mt-4" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Endereço")
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        _vm._s(_vm.corretora.LocationAddress || "Não informado")
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "vx-col w-1/2 mt-4" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Telefone de contato")
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        _vm._s(_vm.corretora.PhoneNumber || "Não informado")
                      )
                    ])
                  ])
                ])
              ]),
              _c("vs-card", { staticClass: "mt-4 p-8 dados-proposta" }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c("div", { staticClass: "vx-col w-1/1" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Nome Responsável Tecnico - SUSEP")
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(_vm._s(_vm.corretora.NameSusep || "Não informado"))
                    ])
                  ]),
                  _c("div", { staticClass: "vx-col  w-1/2 mt-4" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Recadastrado")
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        _vm._s(_vm.corretoraRecadastrado || "Não informado")
                      )
                    ])
                  ])
                ])
              ]),
              _c("vs-card", { staticClass: "mt-4 p-8 dados-proposta" }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c("div", { staticClass: "vx-col w-1/1" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Produtos")
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        _vm._s(_vm.corretora.SUSEPProducts || "Não informado")
                      )
                    ])
                  ])
                ])
              ])
            ],
            1
          ),
          _c(
            "vs-tab",
            { attrs: { label: "Dados Financeiros" } },
            [
              _c("vs-card", { staticClass: "mt-4 p-8 dados-proposta" }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c("div", { staticClass: "vx-col w-full" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Banco")
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        _vm._s(
                          (_vm.financeiro.BankNumber &&
                            (_vm.financeiro.BankNumber + "").padStart(3, "0") +
                              " - " +
                              _vm.financeiro.BankName) ||
                            "Não informado"
                        )
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "vx-col w-1/2 mt-4" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Agência")
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.financeiro.BankBranchNumber +
                              " - " +
                              _vm.financeiro.BankBranchDigit || "Não informado"
                          ) +
                          "\n            "
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "vx-col w-1/2 mt-4" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Conta")
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.financeiro.BankAccountNumber +
                              " - " +
                              _vm.financeiro.BankAccountDigit || "Não informado"
                          ) +
                          "\n            "
                      )
                    ])
                  ])
                ])
              ]),
              _c(
                "div",
                [
                  _c("div", { staticClass: "vx-row mt-12 mb-12" }, [
                    _c(
                      "div",
                      { staticClass: "w-2/3 ml-auto" },
                      [
                        _c(
                          "vs-button",
                          {
                            staticClass: "mr-2",
                            class: { "filtro-ativo": this.filtroAtivo == 1 },
                            attrs: {
                              id: "btn-respAdministrator",
                              type: "filled",
                              color: "rgb(180,180,180)"
                            },
                            on: {
                              click: function($event) {
                                return _vm.filtrarLista("Administrator")
                              }
                            }
                          },
                          [_vm._v("Responsável Técnico")]
                        ),
                        _c(
                          "vs-button",
                          {
                            staticClass: "ml-2",
                            class: { "filtro-ativo": this.filtroAtivo == 2 },
                            attrs: {
                              id: "btn-respFinancialOfficer",
                              type: "filled",
                              color: "rgb(180,180,180)"
                            },
                            on: {
                              click: function($event) {
                                return _vm.filtrarLista("FinancialOfficer")
                              }
                            }
                          },
                          [_vm._v("Responsável Financeiro")]
                        )
                      ],
                      1
                    )
                  ]),
                  _c(
                    "vs-table",
                    {
                      staticClass: "table-list",
                      attrs: {
                        hoverFlat: true,
                        noDataText: "Nenhum responsável financeiro.",
                        data: _vm.listaResponsaveis
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var data = ref.data
                            return _vm._l(data, function(tr, indextr) {
                              return _c(
                                "vs-tr",
                                {
                                  key: indextr,
                                  staticStyle: {
                                    "border-top": "solid 1px black"
                                  },
                                  attrs: { data: tr }
                                },
                                [
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "text-center",
                                      attrs: {
                                        data:
                                          data[indextr]
                                            .ResponsiblePersonDocument
                                      }
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            data[indextr]
                                              .ResponsiblePersonDocument || "-"
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "text-center",
                                      attrs: {
                                        data:
                                          data[indextr].ResponsiblePersonName
                                      }
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            data[indextr].ResponsiblePersonName
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "text-center",
                                      attrs: {
                                        data:
                                          data[indextr]
                                            .ResponsiblePersonPhoneNumber
                                      }
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            data[indextr]
                                              .ResponsiblePersonPhoneNumber
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "text-center",
                                      attrs: {
                                        data:
                                          data[indextr].ResponsiblePersonEmail
                                      }
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            data[indextr].ResponsiblePersonEmail
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            })
                          }
                        }
                      ])
                    },
                    [
                      _c(
                        "template",
                        { slot: "thead" },
                        [
                          _c("vs-th", [_vm._v("CPF")]),
                          _c(
                            "vs-th",
                            { attrs: { "sort-key": "ResponsiblePersonName" } },
                            [_vm._v("Nome")]
                          ),
                          _c("vs-th", [_vm._v("Telefone")]),
                          _c(
                            "vs-th",
                            { attrs: { "sort-key": "ResponsiblePersonEmail" } },
                            [_vm._v("E-mail")]
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.enableInsertNewDocument
            ? _c("vs-tab", { attrs: { label: "Documentos" } }, [
                _c("div", [
                  _c(
                    "div",
                    _vm._l(_vm.listaDocumentos, function(documento, index) {
                      return _c(
                        "vs-card",
                        { key: index, staticClass: "mt-1 p-2" },
                        [
                          _c("div", { staticClass: "vx-row flex" }, [
                            _c("div", { staticClass: "vx-col flex-1" }, [
                              _c("p", { staticClass: "text-sm font-semibold" }),
                              _c("p", { staticClass: "text-lg" }, [
                                _vm._v(
                                  _vm._s(documento.Name || "Não informado")
                                )
                              ]),
                              _c("p", { staticClass: "text-sm pt-4" }, [
                                _c("b", [_vm._v("Status ")]),
                                _vm._v(
                                  _vm._s(documento.StatusDescription) +
                                    "\n                "
                                )
                              ]),
                              _c("p", { staticClass: "text-sm pt-4" }, [
                                _c("b", [_vm._v("Total de anexos ")]),
                                _vm._v(
                                  _vm._s(documento.TotalArchives) +
                                    "\n                "
                                )
                              ])
                            ]),
                            _c("div", { staticClass: "vx-col" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-right w-full flex items-center"
                                },
                                [
                                  documento.HasArchives
                                    ? _c("button", {
                                        staticClass:
                                          "onpoint-download-simple download-button",
                                        on: {
                                          click: function($event) {
                                            return _vm.downloadDocumento(
                                              documento
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  documento.RequestDocumentStatusId == 3 &&
                                  _vm.hasPermission
                                    ? _c("button", {
                                        staticClass:
                                          "onpoint-thumbs-up aprove-button",
                                        on: {
                                          click: function($event) {
                                            return _vm.alterarStatusDocumento(
                                              documento,
                                              1
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  documento.RequestDocumentStatusId == 3 &&
                                  _vm.hasPermission
                                    ? _c("button", {
                                        staticClass:
                                          "onpoint-thumbs-down reprove-button",
                                        on: {
                                          click: function($event) {
                                            return _vm.alterarStatusDocumento(
                                              documento,
                                              4
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.hasPermission
                                    ? _c("button", {
                                        staticClass:
                                          "onpoint-trash trash-button",
                                        on: {
                                          click: function($event) {
                                            return _vm.deletarDocumento(
                                              documento
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ]
                              )
                            ])
                          ])
                        ]
                      )
                    }),
                    1
                  )
                ]),
                _vm.hasPermission
                  ? _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col text-right w-full mt-2" },
                        [
                          _c(
                            "vs-button",
                            {
                              attrs: { color: "primary" },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.mostrarListaDeDocumentos()
                                }
                              }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "font-semibold pr-2 pl-2" },
                                [
                                  _vm._v(
                                    "\n              Adicionar documentos\n            "
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "vs-button",
                            {
                              staticClass: "ml-5",
                              attrs: {
                                disabled: !_vm.possuiDocumentacaoPendente,
                                color: "primary"
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.enviarParaCorretor(
                                    "Solicitação de documentos para o corretor."
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "font-semibold pr-2 pl-2" },
                                [
                                  _vm._v(
                                    "\n              Enviar para o corretor\n            "
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ])
            : _vm._e()
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup-lista-documentos",
          attrs: {
            title: "Solicitar documentação",
            active: _vm.popupListaDocumentos
          },
          on: {
            "update:active": function($event) {
              _vm.popupListaDocumentos = $event
            }
          }
        },
        [
          _c(
            "vs-tabs",
            { attrs: { color: "warning" } },
            [
              _c("vs-tab", { attrs: { label: "Outros documentos" } }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      (_vm.documentosManuaisParaSolicitar || []).length == 0
                        ? _c("div", { staticClass: "vx-row" }, [
                            _c(
                              "div",
                              { staticClass: "vx-col w-full mt-2" },
                              [
                                _c(
                                  "vs-alert",
                                  {
                                    staticClass: "h-auto",
                                    attrs: { color: "warning" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Nenhum documento adicionado. Clique no botão de adicionar\n                  documentos;\n                "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      _vm._l(_vm.documentosManuaisParaSolicitar || [], function(
                        documentoManual,
                        index
                      ) {
                        return _c(
                          "div",
                          { key: index, staticClass: "vx-row" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vx-col md:w-10/12 w-full mt-5 md:pr-2"
                              },
                              [
                                _c("vs-input", {
                                  staticClass: "w-full",
                                  attrs: {
                                    "label-placeholder": "Documento",
                                    size: "large"
                                  },
                                  model: {
                                    value: documentoManual.Name,
                                    callback: function($$v) {
                                      _vm.$set(documentoManual, "Name", $$v)
                                    },
                                    expression: "documentoManual.Name"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vx-col md:w-2/12 w-full mt-5 md:pr-0 md:pl-2 p-0 flex items-center"
                              },
                              [
                                _c("button", {
                                  staticClass:
                                    "onpoint-trash remove-document-button",
                                  on: {
                                    click: function($event) {
                                      return _vm.removeDocumentoManual(index)
                                    }
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      }),
                      _c("div", { staticClass: "vx-row" }, [
                        _c(
                          "div",
                          { staticClass: "vx-col w-full" },
                          [
                            _c(
                              "vs-button",
                              {
                                staticClass: "font-semibold mt-2 float-right",
                                attrs: {
                                  "icon-after": "",
                                  color: "primary",
                                  type: "flat",
                                  icon: "add_circle"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.adicionaDocumentoManual()
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                  Adicionar novo Documento\n                "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "vx-row" }, [
                        _c(
                          "div",
                          { staticClass: "vx-col pt-5 text-right w-full mt-2" },
                          [
                            _c(
                              "vs-button",
                              {
                                attrs: { color: "primary" },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.solicitarDocumentosManuais()
                                  }
                                }
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "font-semibold pr-2 pl-2" },
                                  [
                                    _vm._v(
                                      "\n                    Solicitar documentação\n                  "
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    2
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup-download-arquivos",
          attrs: {
            title: "Anexos do documento",
            active: _vm.popupListaArquivos
          },
          on: {
            "update:active": function($event) {
              _vm.popupListaArquivos = $event
            }
          }
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c("h5", [_vm._v(_vm._s((_vm.documentoSelecionado || {}).Name))])
            ])
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col pt-5 w-full mt-2" },
              _vm._l(_vm.listaDocumentosDetalhes, function(archive, index) {
                return _c("vs-card", { key: index, staticClass: "mt-1 p-2" }, [
                  _c("div", { staticClass: "vx-row flex" }, [
                    _c("div", { staticClass: "vx-col flex-1" }, [
                      _c("p", { staticClass: "text-sm font-semibold" }),
                      _c("p", { staticClass: "text-lg" }, [
                        _vm._v(_vm._s(archive.Name || "Não informado"))
                      ]),
                      _c("p", { staticClass: "text-sm" }, [
                        _c("b", [_vm._v("Anexado em ")]),
                        _vm._v(
                          _vm._s(
                            _vm._f("moment")(archive.CreateDate, "DD/MM/YYYY")
                          ) + "\n              "
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "vx-col" }, [
                      _c(
                        "div",
                        { staticClass: "text-right w-full flex items-center" },
                        [
                          _c("vs-button", {
                            staticClass: "delete",
                            attrs: {
                              color: "primary",
                              type: "flat",
                              size: "large",
                              icon: "get_app"
                            },
                            on: {
                              click: function($event) {
                                return _vm.baixarArquivo(archive)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ])
              }),
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }