var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.onCardLoad,
          expression: "onCardLoad"
        }
      ]
    },
    [
      _c(
        "div",
        { staticClass: "m-1 mr-4 mb-10 px-0 esteiras" },
        [
          _c("breadcrumb", {
            attrs: {
              title: _vm.currentPipe.name || "Pipes",
              actualPage: _vm.currentPipe.name || "Pipes",
              previousPage: "kanban",
              previousPageTitle: "Pipes"
            }
          }),
          _c(
            "div",
            { staticClass: "flex mt-8 mb-6 gap-8 " },
            [
              _c("filtro", { ref: "filtro", attrs: { isFilter: _vm.filtro } }, [
                _c("div", { staticClass: "pt-2 pb-6 px-4" }, [
                  _c(
                    "div",
                    { staticClass: "flex flex-col md:flex-row" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full md:w-5/5 md:pr-3",
                        attrs: {
                          size: "large",
                          "label-placeholder": "Filtro por palava chave"
                        },
                        model: {
                          value: _vm.filterValue.meta,
                          callback: function($$v) {
                            _vm.$set(_vm.filterValue, "meta", $$v)
                          },
                          expression: "filterValue.meta"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "flex flex-row-reverse mt-4" },
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass: "ml-4",
                          attrs: { color: "primary", type: "filled" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.loadSteps(1)
                            }
                          }
                        },
                        [_vm._v("Aplicar filtro")]
                      ),
                      _c(
                        "vs-button",
                        {
                          attrs: {
                            color: "primary",
                            type: "filled",
                            icon: "clear"
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.clearFilter(1)
                            }
                          }
                        },
                        [_vm._v("Limpar filtro")]
                      )
                    ],
                    1
                  )
                ])
              ]),
              _c(
                "FiltroPrioridades",
                {
                  ref: "priorityFilter",
                  attrs: { isPriorityFilter: _vm.priorityFilter }
                },
                [
                  _c(
                    "div",
                    { staticClass: "pt-2 pb-6 px-4" },
                    [
                      _c(
                        "vs-row",
                        {
                          staticClass: "center p-0",
                          attrs: {
                            "vs-type": "flex",
                            "vs-justify": "space-around"
                          }
                        },
                        _vm._l(_vm.cardPriorityList, function(priority, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "m-4" },
                            [
                              _c(
                                "vs-checkbox",
                                {
                                  attrs: { "vs-value": priority.Id },
                                  model: {
                                    value: _vm.priorityIds,
                                    callback: function($$v) {
                                      _vm.priorityIds = $$v
                                    },
                                    expression: "priorityIds"
                                  }
                                },
                                [_vm._v(_vm._s(priority.Name))]
                              )
                            ],
                            1
                          )
                        }),
                        0
                      ),
                      _c(
                        "div",
                        { staticClass: "flex flex-row-reverse mt-4" },
                        [
                          _c(
                            "vs-button",
                            {
                              staticClass: "ml-4",
                              attrs: { color: "primary", type: "filled" },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.loadSteps(2)
                                }
                              }
                            },
                            [_vm._v("Aplicar filtro")]
                          ),
                          _c(
                            "vs-button",
                            {
                              attrs: {
                                color: "primary",
                                type: "filled",
                                icon: "clear"
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.clearFilter(1)
                                }
                              }
                            },
                            [_vm._v("Limpar filtro")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "container-kamban" }, [
        _c(
          "div",
          { staticClass: "flex" },
          [
            _vm.renderComponent
              ? _c("card-data-view", {
                  attrs: {
                    data: _vm.cardData,
                    title: _vm.cardDataViewTitle,
                    type: _vm.cardDataViewType,
                    stepName: (_vm.itemSelected || {}).stepName,
                    currentPipe: _vm.currentPipe,
                    cardId: _vm.cardId,
                    cardMovementUser: _vm.cardMovementUser,
                    metasValidationClickedCard: _vm.metasValidationClickedCard,
                    ownerCard: _vm.ownerCard,
                    ownerList: _vm.ownerList,
                    cardPriorityList: _vm.cardPriorityList,
                    priorityDataClickedCard: _vm.priorityDataClickedCard,
                    permissoesCocorretagemMapfre:
                      _vm.permissoesCocorretagemMapfre,
                    permissoesResseguroMapfre: _vm.permissoesResseguroMapfre,
                    permissoesCosseguroMapfre: _vm.permissoesCosseguroMapfre
                  },
                  on: {
                    reload: _vm.reloadCardDataView,
                    closeCardDataView: _vm.hideCardDataView,
                    refreshPipe: _vm.refreshPipe
                  }
                })
              : _vm._e(),
            _vm._l(_vm.objecSteps, function(step) {
              return _c(
                "div",
                { key: step.id, staticClass: "w-full max-w-sm px-3 step" },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "step-name mb-3 text-lg text-gray-700 flex font-semibold tracking-wide"
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(step.name) + "\n          "
                      ),
                      _c(
                        "vx-tooltip",
                        {
                          attrs: { text: step.description, position: "right" }
                        },
                        [
                          _c("i", {
                            staticClass: "onpoint-info px-2 py-2",
                            staticStyle: { color: "rgba(var(--vs-warning),1)" }
                          })
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "draggable",
                    {
                      staticClass: "draggable-list",
                      staticStyle: { "min-height": "90%" },
                      attrs: {
                        tag: "ul",
                        group: "all-users",
                        "ghost-class": "moving-card",
                        filter: ".action-button",
                        move: _vm.checkCanMove,
                        "data-stepid": step.id,
                        "data-stepType": step.stepType,
                        draggable: _vm.hasPermission ? ".pipe-card" : false,
                        list: step.cards,
                        animation: 200
                      },
                      on: { change: _vm.onDragChange, end: _vm.onDragEnd }
                    },
                    [
                      _vm._l(step.cards, function(stepCard) {
                        return [
                          !stepCard.hasOwnProperty("filtered") ||
                          stepCard.filtered
                            ? _c("card", {
                                key: stepCard.id,
                                staticClass: "pipe-card",
                                attrs: {
                                  data: stepCard.cardData,
                                  object: stepCard,
                                  stepName: step.name
                                },
                                on: { onCardClick: _vm.onCardClick }
                              })
                            : _vm._e()
                        ]
                      }),
                      _c("a", { staticClass: "btn-see-more" }, [
                        _c("b", [
                          step.cards.length < step.countCards
                            ? _c(
                                "p",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.seeMoreCards(
                                        _vm.currentPipe.id,
                                        step.id,
                                        step.cards.length,
                                        _vm.filterValue.meta
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Ver mais\n              "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(
                            "\n              " +
                              _vm._s(step.cards.length) +
                              " / " +
                              _vm._s(step.countCards)
                          )
                        ])
                      ])
                    ],
                    2
                  )
                ],
                1
              )
            })
          ],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }