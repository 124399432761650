var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-4 pl-4 pr-4" },
    [
      _c(
        "vs-tabs",
        { attrs: { color: "warning" } },
        [
          _c(
            "vs-tab",
            { attrs: { label: "Resumo da Proposta" } },
            [
              _vm.endosso.EndorsementType.Id
                ? _c("div", { staticClass: "vx-row mt-6" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col text-right w-full mt-2" },
                      [
                        _c("visualizar-alteracoes", {
                          attrs: {
                            changed: _vm.diffEndosso.length > 0,
                            showDesfazer: false,
                            objectDiff: _vm.diffEndosso,
                            responsavel: _vm.responsavelDataUpdateEndosso
                          }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _c("vs-card", { staticClass: "mt-4 p-8 dados-proposta" }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-1/2" },
                    [
                      _c("p", { staticClass: "text-sm font-semibold mb-1" }, [
                        _vm._v("Responsável")
                      ]),
                      _c(
                        "vs-select",
                        {
                          on: { change: _vm.atualizarResponsavel },
                          model: {
                            value: _vm.selectedOwnerCard,
                            callback: function($$v) {
                              _vm.selectedOwnerCard = $$v
                            },
                            expression: "selectedOwnerCard"
                          }
                        },
                        _vm._l(_vm.ownerList, function(item, index) {
                          return _c("vs-select-item", {
                            key: index,
                            attrs: { value: item, text: item }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col w-1/2" },
                    [
                      _c("p", { staticClass: "text-sm font-semibold mb-1" }, [
                        _vm._v("Prioridade")
                      ]),
                      _c(
                        "vs-select",
                        {
                          on: { change: _vm.atualizarPrioridade },
                          model: {
                            value: _vm.selectedPriorityId,
                            callback: function($$v) {
                              _vm.selectedPriorityId = $$v
                            },
                            expression: "selectedPriorityId"
                          }
                        },
                        _vm._l(_vm.cardPriorityList, function(item, index) {
                          return _c("vs-select-item", {
                            key: index,
                            attrs: { value: item.Id, text: item.Name }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ]),
                _vm.deveExibirResponsavelMovimentacao
                  ? _c("div", { staticClass: "vx-row mt-4" }, [
                      _c("div", { staticClass: "vx-col w-1/2" }, [
                        _c("p", { staticClass: "text-sm font-semibold" }, [
                          _vm._v(_vm._s(_vm.stepName) + " por")
                        ]),
                        _c("p", { staticClass: "text-lg" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                (_vm.cardMovementUser || {}).Name
                                  ? (_vm.cardMovementUser || {}).Name
                                  : "-"
                              ) +
                              "\n            "
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "vx-col w-1/2" }, [
                        _c("p", { staticClass: "text-sm font-semibold" }, [
                          _vm._v("Data da movimentação")
                        ]),
                        _c("p", { staticClass: "text-lg" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm._f("moment")(
                                  (_vm.cardMovementUser || {}).MovingDate
                                    ? (_vm.cardMovementUser || {}).MovingDate
                                    : "-",
                                  "DD/MM/YYYY HH:mm"
                                )
                              ) +
                              "\n            "
                          )
                        ])
                      ])
                    ])
                  : _vm._e()
              ]),
              _c("vs-card", { staticClass: "mt-4 p-8 dados-proposta" }, [
                _vm.Newe
                  ? _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-6/12 w-full md:mt-0 mt-5" },
                        [
                          _c("p", { staticClass: "text-sm font-semibold" }, [
                            _vm._v("Nroº Proposta")
                          ]),
                          _c("p", { staticClass: "text-lg" }, [
                            _vm._v(
                              _vm._s(_vm.proposta.Number || "Não informado")
                            )
                          ])
                        ]
                      )
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "vx-row" }, [
                  _c("div", { staticClass: "vx-col w-6/12" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Corretora")
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(_vm._s((_vm.proposta.Broker || {}).Name))
                    ]),
                    _c("small", [
                      _vm._v(
                        _vm._s(
                          _vm._f("maskCpfCnpj")(
                            (_vm.proposta.Broker || {}).CpfCnpj
                          )
                        )
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "vx-col w-6/12" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Data de solicitação")
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm._f("moment")(
                              _vm.proposta.CreateDate,
                              "DD/MM/YYYY HH:mm"
                            )
                          ) +
                          "\n            "
                      )
                    ])
                  ])
                ])
              ]),
              _vm.proposta.Policy
                ? _c("vs-card", { staticClass: "mt-4 p-8 dados-proposta" }, [
                    _c("div", { staticClass: "vx-row" }, [
                      _c("div", { staticClass: "vx-col w-1/2" }, [
                        _c("p", { staticClass: "text-sm font-semibold" }, [
                          _vm._v("Nroº Apólice")
                        ]),
                        _c("p", { staticClass: "text-lg" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                (_vm.proposta.Policy || {}).Number ||
                                  "Não informado"
                              ) +
                              "\n            "
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "vx-col w-1/2" }, [
                        _c("p", { staticClass: "text-sm font-semibold" }, [
                          _vm._v("Data de emissão")
                        ]),
                        _c("p", { staticClass: "text-lg" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm._f("moment")(
                                  (_vm.proposta.Policy || {}).CreateDate,
                                  "DD/MM/YYYY"
                                )
                              ) +
                              "\n            "
                          )
                        ])
                      ])
                    ])
                  ])
                : _vm._e(),
              _c("vs-card", { staticClass: "mt-4 p-8 dados-proposta" }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c("div", { staticClass: "vx-col w-1/3" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Nroº Proposta")
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(_vm._s(_vm.proposta.Number))
                    ])
                  ]),
                  _c("div", { staticClass: "vx-col w-1/3" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Nroº Endosso")
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(_vm._s(_vm.endosso.Number))
                    ])
                  ]),
                  _c("div", { staticClass: "vx-col w-1/3" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Motivo")
                    ]),
                    _vm.endosso.ReasonEndorsementRequest
                      ? _c("p", { staticClass: "text-lg" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.endosso.ReasonEndorsementRequest.Name
                              ) +
                              "\n            "
                          )
                        ])
                      : _vm._e()
                  ])
                ]),
                _c("div", { staticClass: "vx-row" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-1/3" },
                    [
                      _c("p", { staticClass: "text-sm font-semibold pb-3" }, [
                        _vm._v("Tipo")
                      ]),
                      _vm._l(_vm.tiposEndosso, function(text, index) {
                        return _c(
                          "span",
                          { key: index, staticClass: "text-lg inline-flex" },
                          [
                            _vm.verifyUndefined(text)
                              ? _c("div", { staticClass: "inline-flex" }, [
                                  _c("i", {
                                    staticClass:
                                      "onpoint-check-circle my-auto mr-5"
                                  }),
                                  _c("p", {
                                    domProps: { innerHTML: _vm._s(text) }
                                  })
                                ])
                              : _vm._e()
                          ]
                        )
                      })
                    ],
                    2
                  )
                ])
              ]),
              _vm.proposta.PolicyHolder.BankAccounts.length > 0
                ? _c(
                    "vs-card",
                    { staticClass: "mt-4 p-8 dados-proposta" },
                    [
                      _c("p", { staticClass: "text-sm font-semibold" }, [
                        _vm._v("Dados Bancários do Beneficiário:")
                      ]),
                      _c("br"),
                      _vm._l(
                        _vm.proposta.PolicyHolder.BankAccounts.slice(0, 1),
                        function(dados, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "vx-row",
                              staticStyle: {
                                "padding-bottom": "5px",
                                "margin-bottom": "10px"
                              }
                            },
                            [
                              _c("div", { staticClass: "vx-col w-1/3" }, [
                                _c(
                                  "p",
                                  { staticClass: "text-sm font-semibold" },
                                  [_vm._v("Tipo de conta")]
                                ),
                                _c("p", { staticClass: "text-lg" }, [
                                  _vm._v(_vm._s(dados.BankAccountType.Name))
                                ])
                              ]),
                              _c("div", { staticClass: "vx-col w-1/3" }, [
                                _c(
                                  "p",
                                  { staticClass: "text-sm font-semibold" },
                                  [_vm._v("Agencia")]
                                ),
                                _c("p", { staticClass: "text-lg" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(dados.Branch) +
                                      "\n              "
                                  ),
                                  dados.DigitBranch
                                    ? _c("strong", [
                                        _vm._v(
                                          "( " +
                                            _vm._s(dados.DigitBranch) +
                                            " )"
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              ]),
                              _c("div", { staticClass: "vx-col w-1/3" }, [
                                _c(
                                  "p",
                                  { staticClass: "text-sm font-semibold" },
                                  [_vm._v("Conta")]
                                ),
                                _c("p", { staticClass: "text-lg" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(dados.Number) +
                                      "\n              "
                                  ),
                                  dados.DigitNumber
                                    ? _c("strong", [
                                        _vm._v(
                                          "( " +
                                            _vm._s(dados.DigitNumber) +
                                            " )"
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              ]),
                              _c("div", { staticClass: "vx-col w-2/3" }, [
                                _c("br"),
                                _c(
                                  "p",
                                  { staticClass: "text-sm font-semibold" },
                                  [_vm._v("Banco")]
                                ),
                                _c("p", { staticClass: "text-lg" }, [
                                  _vm._v(_vm._s(dados.Bank.Name))
                                ])
                              ])
                            ]
                          )
                        }
                      )
                    ],
                    2
                  )
                : _vm._e(),
              _c("vs-card", { staticClass: "p-8 dados-proposta" }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c("div", { staticClass: "vx-col w-1/2" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Tomador")
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.proposta.PolicyHolder.Name || "Não informado"
                          ) +
                          "\n            "
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "vx-col w-1/2" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Cnpj")
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm._f("maskCpfCnpj")(
                              _vm.proposta.PolicyHolder.CpfCnpj ||
                                "Não informado"
                            )
                          ) +
                          "\n            "
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "vx-row mb-6 mt-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-1/2" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "detalhe-tomador",
                              params: {
                                tomadorUniqueId:
                                  _vm.proposta.PolicyHolder.UniqueId
                              }
                            },
                            target: "_blank"
                          }
                        },
                        [
                          _c("span", { staticClass: "font-semibold" }, [
                            _vm._v(
                              "\n                Visualizar cadastro completo do tomador\n              "
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _vm.proposta.PolicyHolderBranch
                  ? _c("div", { staticClass: "vx-row" }, [
                      _c("div", { staticClass: "vx-col w-1/2" }, [
                        _c("p", { staticClass: "text-sm font-semibold" }, [
                          _vm._v("Tomador Filial")
                        ]),
                        _c("p", { staticClass: "text-lg" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.proposta.PolicyHolderBranch.Name ||
                                  "Não informado"
                              ) +
                              "\n            "
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "vx-col w-1/2" }, [
                        _c("p", { staticClass: "text-sm font-semibold" }, [
                          _vm._v("Cnpj Filial")
                        ]),
                        _c("p", { staticClass: "text-lg" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm._f("maskCpfCnpj")(
                                  _vm.proposta.PolicyHolderBranch.CpfCnpj ||
                                    "Não informado"
                                )
                              ) +
                              "\n            "
                          )
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm.proposta.PolicyHolderBranch
                  ? _c("div", { staticClass: "vx-row" }, [
                      _c("div", { staticClass: "vx-col w-1/2" }, [
                        _c("p", { staticClass: "text-sm font-semibold" }, [
                          _vm._v("Tomador Filial")
                        ]),
                        _c("p", { staticClass: "text-lg" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.proposta.PolicyHolderBranch.Name ||
                                  "Não informado"
                              ) +
                              "\n              "
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "vx-col w-1/2" }, [
                        _c("p", { staticClass: "text-sm font-semibold" }, [
                          _vm._v("Cnpj Filial")
                        ]),
                        _c("p", { staticClass: "text-lg" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm._f("maskCpfCnpj")(
                                  _vm.proposta.PolicyHolderBranch.CpfCnpj ||
                                    "Não informado"
                                )
                              ) +
                              "\n            "
                          )
                        ])
                      ])
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "vx-row mt-6" }, [
                  _c("div", { staticClass: "vx-col w-1/2" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Segurado")
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.proposta.Insured.Name || "Não informado") +
                          "\n            "
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "vx-col w-1/2" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Cpf/Cnpj")
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm._f("maskCpfCnpj")(
                              _vm.proposta.Insured.CpfCnpj || "Não informado"
                            )
                          ) +
                          "\n            "
                      )
                    ])
                  ])
                ])
              ]),
              _c("vs-card", { staticClass: "p-8 pt-4 pb-4 dados-proposta" }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c("div", { staticClass: "vx-col w-1/2" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Grupo Principal")
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.proposta.ModalityGroup.Name || "Não informado"
                          ) +
                          "\n            "
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "vx-col w-1/2" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Modalidade Principal")
                    ]),
                    _vm.proposta.Modality
                      ? _c("p", { staticClass: "text-lg" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.proposta.Modality.Name) +
                              "\n              "
                          ),
                          _c("b", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.proposta.Modality.IsDeleted
                                    ? "(Modalidade Inativada)"
                                    : ""
                                )
                            )
                          ])
                        ])
                      : _c("p", { staticClass: "text-lg" }, [
                          _vm._v("Não informado")
                        ])
                  ])
                ]),
                _c("div", { staticClass: "vx-row mt-6" }, [
                  _c("div", { staticClass: "vx-col w-1/4" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Importância Segurada")
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm._f("maskCurrency")(
                              _vm.proposta.InsuredAmountValue
                            )
                          ) +
                          "\n            "
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "vx-col w-1/4" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Inicio Vigência")
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm._f("moment")(
                              _vm.proposta.StartDate,
                              "DD/MM/YYYY"
                            )
                          ) +
                          "\n            "
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "vx-col w-1/4" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Fim Vigência")
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm._f("moment")(_vm.proposta.EndDate, "DD/MM/YYYY")
                          ) +
                          "\n            "
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "vx-col w-1/4" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Prazo")
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(_vm._s(_vm.proposta.DeadlineDays) + " dias")
                    ])
                  ])
                ])
              ]),
              _vm.proposta.ComplementaryModalityId
                ? _c(
                    "vs-card",
                    { staticClass: "p-8 pt-4 pb-4 dados-proposta" },
                    [
                      _c("div", { staticClass: "vx-row" }, [
                        _c("div", { staticClass: "vx-col w-1/2" }, [
                          _c("p", { staticClass: "text-sm font-semibold" }, [
                            _vm._v("Modalidade Complementar")
                          ]),
                          _c("p", { staticClass: "text-lg" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.proposta.ComplementaryModality.Name ||
                                    "Não informado"
                                ) +
                                "\n            "
                            )
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "vx-row mt-6" }, [
                        _c("div", { staticClass: "vx-col w-1/4" }, [
                          _c("p", { staticClass: "text-sm font-semibold" }, [
                            _vm._v("Importância Segurada")
                          ]),
                          _c("p", { staticClass: "text-lg" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("maskCurrency")(
                                    _vm.proposta.ComplementaryInsuredAmountValue
                                  )
                                ) +
                                "\n            "
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "vx-col w-1/4" }, [
                          _c("p", { staticClass: "text-sm font-semibold" }, [
                            _vm._v("Inicio Vigência")
                          ]),
                          _c("p", { staticClass: "text-lg" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("moment")(
                                    _vm.proposta.ComplementaryStartDate,
                                    "DD/MM/YYYY"
                                  )
                                ) +
                                "\n            "
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "vx-col w-1/4" }, [
                          _c("p", { staticClass: "text-sm font-semibold" }, [
                            _vm._v("Fim Vigência")
                          ]),
                          _c("p", { staticClass: "text-lg" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("moment")(
                                    _vm.proposta.ComplementaryEndDate,
                                    "DD/MM/YYYY"
                                  )
                                ) +
                                "\n            "
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "vx-col w-1/4" }, [
                          _c("p", { staticClass: "text-sm font-semibold" }, [
                            _vm._v("Prazo")
                          ]),
                          _c("p", { staticClass: "text-lg" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.proposta.ComplementaryDeadlineDays) +
                                " dias\n            "
                            )
                          ])
                        ])
                      ])
                    ]
                  )
                : _vm._e(),
              _c("vs-card", { staticClass: "mt-4 p-8 dados-proposta" }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col" },
                    [
                      _c("p", { staticClass: "text-sm font-semibold" }, [
                        _vm._v("Coberturas adicionais")
                      ]),
                      (_vm.proposta.ProposalCoverages || []).length
                        ? _vm._l(_vm.proposta.ProposalCoverages, function(
                            item
                          ) {
                            return _c(
                              "p",
                              { key: item.Id, staticClass: "text-lg" },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(item.Coverage.Name) +
                                    "\n              "
                                )
                              ]
                            )
                          })
                        : _c("p", { staticClass: "text-lg" }, [
                            _vm._v("\n              Não consta\n            ")
                          ])
                    ],
                    2
                  )
                ])
              ]),
              _c("div", { staticClass: "vx-row mt-6" }, [
                _c(
                  "div",
                  { staticClass: "vx-col text-right w-full mt-2" },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "resumo-proposta-visao-seguradora",
                            params: {
                              propostaUniqueId: this.proposta.UniqueId,
                              proposalType: "Endosso"
                            }
                          },
                          target: "_blank"
                        }
                      },
                      [
                        _vm.perfilHelpers.checkPermission([
                          _vm.perfilHelpers.menuFuncionalitiesEnum
                            .GERENCIAR_PROPOSTAS_SEGURADORA,
                          _vm.perfilHelpers.menuFuncionalitiesEnum
                            .VISUALIZAR_PROPOSTAS_SEGURADORA
                        ])
                          ? _c("vs-button", { attrs: { color: "primary" } }, [
                              _c(
                                "span",
                                { staticClass: "font-semibold pr-2 pl-2" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        (_vm.perfilHelpers.checkPermission([
                                          _vm.perfilHelpers
                                            .menuFuncionalitiesEnum
                                            .GERENCIAR_PROPOSTAS_SEGURADORA
                                        ])
                                          ? "Editar"
                                          : "Visualizar") +
                                          " informações da Proposta"
                                      ) +
                                      "\n              "
                                  )
                                ]
                              )
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c("vs-tab", { attrs: { label: "Histórico do Motor" } }, [
            !_vm.regrasExecutadas || _vm.regrasExecutadas.length == 0
              ? _c("div", { staticClass: "vx-row" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full mt-2" },
                    [
                      _c(
                        "vs-alert",
                        { staticClass: "h-auto", attrs: { color: "warning" } },
                        [
                          _vm._v(
                            "\n            Nenhuma regra foi executada nessa proposta.\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col pt-5 w-full mt-2" },
                _vm._l(_vm.regrasExecutadas, function(regra, index) {
                  return _c(
                    "vs-collapse",
                    { key: index, attrs: { type: "margin" } },
                    [
                      _c("vs-collapse-item", [
                        _c(
                          "div",
                          { attrs: { slot: "header" }, slot: "header" },
                          [
                            _c("p", { staticClass: "text-sm font-semibold" }),
                            _c("p", { staticClass: "text-lg" }, [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    (regra || {}).EngineExecutionType.Name ||
                                      "Não informado"
                                  ) +
                                  "\n                "
                              )
                            ]),
                            _c("p", { staticClass: "text-sm" }, [
                              _c("b", [_vm._v("Executada em ")]),
                              _vm._v(
                                _vm._s(
                                  _vm._f("moment")(
                                    (regra || {}).CreateDate,
                                    "DD/MM/YYYY"
                                  )
                                ) + "\n                "
                              )
                            ])
                          ]
                        ),
                        _c("div", { staticClass: "vx-row flex" }, [
                          _c(
                            "div",
                            { staticClass: "vx-col flex-1" },
                            [
                              _c(
                                "vs-table",
                                {
                                  attrs: {
                                    data:
                                      ((regra || {}).RuleEngineResult || {})
                                        .RuleResults || [],
                                    noDataText:
                                      "Nenhum histórico de regras executado."
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var data = ref.data
                                          return _vm._l(data, function(
                                            tr,
                                            indextr
                                          ) {
                                            return _c(
                                              "vs-tr",
                                              {
                                                key: indextr,
                                                style: _vm.getRowStyle(
                                                  data[indextr]
                                                )
                                              },
                                              [
                                                _c(
                                                  "vs-td",
                                                  { attrs: { data: indextr } },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "flex" },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(indextr + 1)
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "vs-td",
                                                  {
                                                    attrs: {
                                                      data:
                                                        data[indextr].RuleName
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "flex" },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .RuleName
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "vs-td",
                                                  {
                                                    attrs: {
                                                      data:
                                                        data[indextr].Success
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "flex" },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              data[indextr]
                                                                .Success
                                                                ? "Sim"
                                                                : "Não"
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "vs-td",
                                                  {
                                                    attrs: {
                                                      data:
                                                        data[indextr].Messages
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "flex" },
                                                      [
                                                        (
                                                          data[indextr]
                                                            .Messages || []
                                                        ).length == 0
                                                          ? _c("span")
                                                          : _vm._e(),
                                                        (
                                                          data[indextr]
                                                            .Messages || []
                                                        ).length > 0
                                                          ? _c("div", [
                                                              _c(
                                                                "ul",
                                                                _vm._l(
                                                                  data[indextr]
                                                                    .Messages ||
                                                                    [],
                                                                  function(
                                                                    message,
                                                                    indexMsg
                                                                  ) {
                                                                    return _c(
                                                                      "li",
                                                                      {
                                                                        key: indexMsg
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                  " +
                                                                            _vm._s(
                                                                              message
                                                                            ) +
                                                                            "\n                                "
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              )
                                                            ])
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          })
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c(
                                    "template",
                                    { slot: "thead" },
                                    [
                                      _c("vs-th", [
                                        _vm._v(
                                          "\n                        Etapa\n                      "
                                        )
                                      ]),
                                      _c("vs-th", [
                                        _vm._v(
                                          "\n                        Regra\n                      "
                                        )
                                      ]),
                                      _c("vs-th", [
                                        _vm._v(
                                          "\n                        Executada com sucesso?\n                      "
                                        )
                                      ]),
                                      _c("vs-th", [
                                        _vm._v(
                                          "\n                        Mensagem\n                      "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ])
                      ])
                    ],
                    1
                  )
                }),
                1
              )
            ])
          ]),
          _c("vs-tab", { attrs: { label: "Follow Up" } }, [
            _vm.observacoes.length == 0
              ? _c("div", { staticClass: "vx-row" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full mt-2" },
                    [
                      _c(
                        "vs-alert",
                        { staticClass: "h-auto", attrs: { color: "warning" } },
                        [
                          _vm._v(
                            "\n            Nenhum follow up até o momento.\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "vx-row" },
              _vm._l(_vm.observacoes, function(mensagem, index) {
                return _c(
                  "vs-card",
                  { key: index, staticClass: "p-8 pt-4 pb-4" },
                  [
                    _c("h6", { staticClass: "flex items-baseline" }, [
                      _c("span", { staticClass: "text-primary mr-2 mb-2" }, [
                        _vm._v(_vm._s(mensagem.CreateByPerson))
                      ]),
                      _c("small", [
                        _vm._v(
                          "escreveu em\n              " +
                            _vm._s(
                              _vm._f("dateFormat")(
                                new Date(mensagem.CreateDate),
                                "DD/MM/YYYY"
                              )
                            )
                        )
                      ])
                    ]),
                    _c("p", { staticClass: "pl-4" }, [
                      _vm._v(_vm._s(mensagem.Observation))
                    ])
                  ]
                )
              }),
              1
            ),
            _vm.hasPermission
              ? _c("div", { staticClass: "vx-row" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col pt-5 w-full mt-2" },
                    [
                      _c("label", [_vm._v("Comentário")]),
                      _c("vs-textarea", {
                        model: {
                          value: _vm.observation,
                          callback: function($$v) {
                            _vm.observation = $$v
                          },
                          expression: "observation"
                        }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _vm.hasPermission
              ? _c("div", { staticClass: "vx-row" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col text-right w-full mt-2" },
                    [
                      _c(
                        "vs-button",
                        {
                          attrs: {
                            disabled: (_vm.observation || "").length == 0,
                            color: "primary"
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.enviarObservacao()
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "font-semibold pr-2 pl-2" },
                            [
                              _vm._v(
                                "\n              Enviar para o corretor\n            "
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              : _vm._e()
          ]),
          _c("vs-tab", { attrs: { label: "Documentos Solicitados" } }, [
            _vm.documentos.length == 0
              ? _c("div", { staticClass: "vx-row" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full mt-2" },
                    [
                      _c(
                        "vs-alert",
                        { staticClass: "h-auto", attrs: { color: "warning" } },
                        [
                          _vm._v(
                            "\n            Nenhum documento solicitado para a corretora.\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _c("div", [
              _c(
                "div",
                _vm._l(_vm.documentos, function(documento, index) {
                  return _c(
                    "vs-card",
                    { key: index, staticClass: "mt-1 p-2" },
                    [
                      _c("div", { staticClass: "vx-row flex" }, [
                        _c("div", { staticClass: "vx-col flex-1" }, [
                          _c("p", { staticClass: "text-sm font-semibold" }),
                          _c("p", { staticClass: "text-lg" }, [
                            _vm._v(_vm._s(documento.Name || "Não informado"))
                          ]),
                          _c("p", { staticClass: "text-sm pt-4" }, [
                            _c("b", [_vm._v("Status ")]),
                            _vm._v(
                              _vm._s(documento.StatusDescription) +
                                "\n                "
                            )
                          ]),
                          _c("p", { staticClass: "text-sm pt-4" }, [
                            _c("b", [_vm._v("Total de anexos ")]),
                            _vm._v(
                              _vm._s(documento.TotalArchives) +
                                "\n                "
                            )
                          ]),
                          _c("p", { staticClass: "text-sm" }, [
                            _c("b", [_vm._v("Solicitado em ")]),
                            _vm._v(
                              _vm._s(
                                _vm._f("moment")(
                                  documento.CreateDate,
                                  "DD/MM/YYYY"
                                )
                              ) + "\n                "
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "vx-col" }, [
                          _c(
                            "div",
                            {
                              staticClass: "text-right w-full flex items-center"
                            },
                            [
                              documento.TotalArchives > 0
                                ? _c("button", {
                                    staticClass:
                                      "onpoint-download-simple download-button",
                                    on: {
                                      click: function($event) {
                                        return _vm.downloadDocumento(documento)
                                      }
                                    }
                                  })
                                : _vm._e(),
                              documento.StatusId == 3 && _vm.hasPermission
                                ? _c("button", {
                                    staticClass:
                                      "onpoint-thumbs-up aprove-button",
                                    on: {
                                      click: function($event) {
                                        return _vm.alterarStatusDocumento(
                                          documento,
                                          1
                                        )
                                      }
                                    }
                                  })
                                : _vm._e(),
                              documento.StatusId == 3 && _vm.hasPermission
                                ? _c("button", {
                                    staticClass:
                                      "onpoint-thumbs-down reprove-button",
                                    on: {
                                      click: function($event) {
                                        return _vm.alterarStatusDocumento(
                                          documento,
                                          4
                                        )
                                      }
                                    }
                                  })
                                : _vm._e(),
                              _vm.hasPermission
                                ? _c("button", {
                                    staticClass: "onpoint-trash trash-button",
                                    on: {
                                      click: function($event) {
                                        return _vm.deletarDocumento(documento)
                                      }
                                    }
                                  })
                                : _vm._e()
                            ]
                          )
                        ])
                      ])
                    ]
                  )
                }),
                1
              )
            ]),
            _vm.hasPermission
              ? _c("div", { staticClass: "vx-row" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col text-right w-full mt-2" },
                    [
                      _c(
                        "vs-button",
                        {
                          attrs: { color: "primary" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.mostrarListaDeDocumentos()
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "font-semibold pr-2 pl-2" },
                            [
                              _vm._v(
                                "\n              Adicionar documentos\n            "
                              )
                            ]
                          )
                        ]
                      ),
                      _c(
                        "vs-button",
                        {
                          staticClass: "ml-5",
                          attrs: {
                            disabled: !_vm.possuiDocumentacaoPendente,
                            color: "primary"
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.enviarParaCorretor(
                                "Solicitação de documentos para o corretor."
                              )
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "font-semibold pr-2 pl-2" },
                            [
                              _vm._v(
                                "\n              Enviar para o corretor\n            "
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              : _vm._e()
          ])
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup-lista-documentos",
          attrs: {
            title: "Solicitar documentação",
            active: _vm.popupListaDocumentos
          },
          on: {
            "update:active": function($event) {
              _vm.popupListaDocumentos = $event
            }
          }
        },
        [
          _c(
            "vs-tabs",
            { attrs: { color: "warning" } },
            [
              _c("vs-tab", { attrs: { label: "Lista de Documentos" } }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c("div", { staticClass: "vx-col w-full" }, [
                    (_vm.documentosPadrao || []).length > 0
                      ? _c(
                          "ul",
                          { staticClass: "mt-8" },
                          _vm._l(_vm.documentosPadrao, function(
                            documento,
                            index
                          ) {
                            return _c(
                              "li",
                              {
                                key: documento.Id,
                                class: { "pt-5": index > 0 }
                              },
                              [
                                _vm.documentoJaFoiSolicitado(documento.Name)
                                  ? _c(
                                      "vs-checkbox",
                                      {
                                        attrs: { "vs-value": documento.Id },
                                        model: {
                                          value: _vm.documentosParaSolicitar,
                                          callback: function($$v) {
                                            _vm.documentosParaSolicitar = $$v
                                          },
                                          expression: "documentosParaSolicitar"
                                        }
                                      },
                                      [_vm._v(_vm._s(documento.Name))]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    (_vm.documentosPadrao || []).length == 0
                      ? _c("div", [
                          _c("p", [
                            _vm._v("Nenhum tipo de documento encontrado!")
                          ])
                        ])
                      : _vm._e()
                  ])
                ]),
                (_vm.documentosPadrao || []).length > 0
                  ? _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col pt-5 text-right w-full mt-2" },
                        [
                          _c(
                            "vs-button",
                            {
                              attrs: { color: "primary" },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.solicitarDocumentos()
                                }
                              }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "font-semibold pr-2 pl-2" },
                                [
                                  _vm._v(
                                    "\n                Solicitar documentação\n              "
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ]),
              _c("vs-tab", { attrs: { label: "Outros documentos" } }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      (_vm.documentosManuaisParaSolicitar || []).length == 0
                        ? _c("div", { staticClass: "vx-row" }, [
                            _c(
                              "div",
                              { staticClass: "vx-col w-full mt-2" },
                              [
                                _c(
                                  "vs-alert",
                                  {
                                    staticClass: "h-auto",
                                    attrs: { color: "warning" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Nenhum documento adicionado. Clique no botão de adicionar\n                  documentos;\n                "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      _vm._l(_vm.documentosManuaisParaSolicitar || [], function(
                        documentoManual,
                        index
                      ) {
                        return _c(
                          "div",
                          { key: index, staticClass: "vx-row" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vx-col md:w-10/12 w-full mt-5 md:pr-2"
                              },
                              [
                                _c("vs-input", {
                                  staticClass: "w-full",
                                  attrs: {
                                    "label-placeholder": "Documento",
                                    size: "large"
                                  },
                                  model: {
                                    value: documentoManual.Name,
                                    callback: function($$v) {
                                      _vm.$set(documentoManual, "Name", $$v)
                                    },
                                    expression: "documentoManual.Name"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vx-col md:w-2/12 w-full mt-5 md:pr-0 md:pl-2 p-0 flex items-center"
                              },
                              [
                                _c("vs-button", {
                                  staticClass: "delete mt-4 ml-2",
                                  attrs: {
                                    color: "primary",
                                    type: "flat",
                                    size: "large",
                                    icon: "delete"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.removeDocumentoManual(index)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      }),
                      _c("div", { staticClass: "vx-row" }, [
                        _c(
                          "div",
                          { staticClass: "vx-col w-full" },
                          [
                            _c(
                              "vs-button",
                              {
                                staticClass: "font-semibold mt-2 float-right",
                                attrs: {
                                  "icon-after": "",
                                  color: "primary",
                                  type: "flat",
                                  icon: "add_circle"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.adicionaDocumentoManual()
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                  Adicionar novo Documento\n                "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "vx-row" }, [
                        _c(
                          "div",
                          { staticClass: "vx-col pt-5 text-right w-full mt-2" },
                          [
                            _c(
                              "vs-button",
                              {
                                attrs: { color: "primary" },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.solicitarDocumentosManuais()
                                  }
                                }
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "font-semibold pr-2 pl-2" },
                                  [
                                    _vm._v(
                                      "\n                    Solicitar documentação\n                  "
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    2
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup-download-arquivos",
          attrs: {
            title: "Anexos do documento",
            active: _vm.popupListaArquivos
          },
          on: {
            "update:active": function($event) {
              _vm.popupListaArquivos = $event
            }
          }
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c("h5", [_vm._v(_vm._s((_vm.documentoSelecionado || {}).Name))])
            ])
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col pt-5 w-full mt-2" },
              _vm._l(_vm.arquivosParaDownload, function(archive, index) {
                return _c("vs-card", { key: index, staticClass: "mt-1 p-2" }, [
                  _c("div", { staticClass: "vx-row flex" }, [
                    _c("div", { staticClass: "vx-col flex-1" }, [
                      _c("p", { staticClass: "text-sm font-semibold" }),
                      _c("p", { staticClass: "text-lg" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              archive.OriginalFilename || "Não informado"
                            ) +
                            "\n                " +
                            _vm._s(archive.Extension) +
                            "\n              "
                        )
                      ]),
                      _c("p", { staticClass: "text-sm" }, [
                        _c("b", [_vm._v("Anexado em ")]),
                        _vm._v(
                          _vm._s(
                            _vm._f("moment")(archive.CreateDate, "DD/MM/YYYY")
                          ) + "\n              "
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "vx-col" }, [
                      _c(
                        "div",
                        { staticClass: "text-right w-full flex items-center" },
                        [
                          _c("vs-button", {
                            staticClass: "delete",
                            attrs: {
                              color: "primary",
                              type: "flat",
                              size: "large",
                              icon: "get_app"
                            },
                            on: {
                              click: function($event) {
                                return _vm.baixarArquivo(archive)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ])
              }),
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }